import React from 'react';
import { Failure as FailurePage } from 'ekyc-web-module';
import Header from '../../components/Header';
import { OverrideGlobalStyle } from '../../styles/GlobalStyles';
import QueryString from 'query-string';
import { useLocation } from '@reach/router';

const failure = () => {
  const location = useLocation();
  const state = location.state || {}; // this is the props passed from the previous page

  let search = typeof window !== 'undefined' ? window.location.search : '';
  const { verificationId } = QueryString.parse(search) || {};

  const props = {
    verificationId,
    ...state
  };

  return (
    <>
      <OverrideGlobalStyle />
      <Header align="left" />
      <FailurePage {...props} />
    </>
  );
};

export default failure;
